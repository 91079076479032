.carousel-header {
    padding: .5rem 0;
    display: flex;
    color: transparent;
    align-items: center;
    /* background: white; */
}

.carousel-button {
    /* border-radius: 50%;
    padding: .25rem; */
    /* background: rgb(242, 242, 247); */
    width: 2rem;
    height: 2rem;
    text-align: center;
    vertical-align: middle;
    border-radius: 50%;
    padding: 0;
}


.carousel {
    width: 100%;
    height: 100%;
    background: transparent;
}

.carousel-inner {
    width: 100%;
    height: auto;
    background-position: top;
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: .5rem;
    display: flex;
    /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
    box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
}