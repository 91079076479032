@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap);
/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.toggle {
    height: 30px;
    width: 60px;
    border-radius: 50px;
    margin: auto;
    background-image: linear-gradient(aqua, skyblue);
    position: relative;
    cursor: pointer;
}

.toggle.night {
    background-image: linear-gradient(midnightblue, rebeccapurple);
}

.notch {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: yellow;
    position: absolute;
    top: 5px;
    left: 5px;
    box-shadow: 0 0 5px yellow;
    z-index: 1;
    transition: all 0.3s ease;
}

.notch > .crater {
    background: burlywood;
    border-radius: 50%;
    position: absolute;
    opacity: 0;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.4) inset;
}
.night .crater {
    opacity: 0.4;
}

.crater:first-child {
    left: 2px;
    top: 3px;
    height: 3px;
    width: 8px;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
}

.crater:nth-child(2) {
    left: 3px;
    bottom: 3px;
    height: 4px;
    width: 5px;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}

.crater:last-child {
    right: 2px;
    top: 3px;
    height: 3px;
    width: 5px;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}

.night > .notch {
    background: whitesmoke;
    box-shadow: 0 0 5px whitesmoke;
    -webkit-transform: translate(30px, 0);
            transform: translate(30px, 0);
}

.shape {
    position: absolute;
    background: whitesmoke;
    border-radius: 50%;
    transition: all 0.3s ease;
}

.shape.sm {
    height: 2px;
    width: 10px;
    top: 40%;
    left: 70%;
}

.shape.md {
    height: 3px;
    width: 15px;
    top: 25%;
    left: 30%;
    z-index: 2;
}

.shape.lg {
    height: 3px;
    width: 20px;
    top: 60%;
    left: 40%;
}

.night .shape {
    background: lightgray;
    box-shadow: 0 0 10px 2px violet;
}

.night .shape.sm {
    height: 3px;
    width: 3px;
    -webkit-transform: translate(-35px, -2px);
            transform: translate(-35px, -2px);
}

.night .shape.sm:first-of-type {
    height: 3px;
    width: 3px;
    -webkit-transform: translate(-18px, 3px);
            transform: translate(-18px, 3px);
}

.night .shape.md {
    height: 5px;
    width: 5px;
    -webkit-transform: translate(0, -3px);
            transform: translate(0, -3px);
}

.night .shape.lg {
    height: 6px;
    width: 6px;
    -webkit-transform: translate(-15px, 0);
            transform: translate(-15px, 0);
}
.carousel-header {
    padding: .5rem 0;
    display: -webkit-flex;
    display: flex;
    color: transparent;
    -webkit-align-items: center;
            align-items: center;
    /* background: white; */
}

.carousel-button {
    /* border-radius: 50%;
    padding: .25rem; */
    /* background: rgb(242, 242, 247); */
    width: 2rem;
    height: 2rem;
    text-align: center;
    vertical-align: middle;
    border-radius: 50%;
    padding: 0;
}


.carousel {
    width: 100%;
    height: 100%;
    background: transparent;
}

.carousel-inner {
    width: 100%;
    height: auto;
    background-position: top;
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: .5rem;
    display: -webkit-flex;
    display: flex;
    /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
    box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
}
/* FONT: Poppins */

/* FONT: Gilroy */
@font-face {
    font-family: "Gilroy";
    src: url(/static/media/Gilroy-Regular.e243f66e.woff);
    font-style: normal;
    font-weight: 400;
} 
@font-face {
    font-family: "Gilroy";
    src: url(/static/media/Gilroy-Light.9e5c8f74.woff);
    font-style: normal;
    font-weight: 200;
} 
@font-face {
    font-family: "Gilroy";
    src: url(/static/media/Gilroy-ExtraBold.a3fdd042.woff);
    font-style: normal;
    font-weight: 600;
} 
/* FONT: SFPro */
@font-face {
    font-family: 'SFPro';
    src: url(/static/media/SF-Pro-Thin.695d5261.woff);
    font-style: normal;
    font-weight: 100;
  }
@font-face {
font-family: 'SFPro';
src: url(/static/media/SF-Pro-Regular.4958d20b.woff);
font-style: normal;
font-weight: 400;
}
@font-face {
font-family: 'SFPro';
src: url(/static/media/SF-Pro-Medium.ce5498a8.woff);
font-style: normal;
font-weight: 500;
}
@font-face {
font-family: 'SFPro';
src: url(/static/media/SF-Pro-Semibold.ea2a1cdd.woff) format('woff');
font-style: normal;
font-weight: 600;
}
@font-face {
font-family: 'SFPro';
src: url(/static/media/SF-Pro-Bold.42588a39.woff);
font-style: normal;
font-weight: 700;
}



* {
    font-family: 'Poppins', Arial, sans-serif;
    /* font-family: "Gilroy", sans-serif; */
    /* font-family: "SFPro", sans-serif ; */
    font-weight: 400;
    color: rgb(58,58,60);
    line-height: 1.5;
}

/* Theme classes */
.dark-mode {
    -webkit-filter: invert(1) hue-rotate(180deg);
            filter: invert(1) hue-rotate(180deg);
    /* animation: fadeIn .25s; */
}
.invert {
    -webkit-filter: invert(1) hue-rotate(180deg);
            filter: invert(1) hue-rotate(180deg);
    /* animation: fadeIn .25s; */
}
@-webkit-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}
@keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}



/* DEFAULT HUGE SCREEN BREAKPOINT CLASSES */
.is-grey {
    background-color: grey;
}

.is-dark {
    color: rgb(44, 44, 46);
}

.is-white {
    color: white;
}

.is-semidark {
    color: rgb(115, 115, 115);
}

.is-background-white {
    background: white;
}

.is-rainbow {
    background-image: linear-gradient(to right, #2657eb, #ee0979, #ff6a00);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.is-outline {
    -webkit-text-fill-color: white;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #252733;    
}

.is-full-height {
    height: 80vh;
}

.is-gilroy {
    font-family: "Gilroy", sans-serif;
}

.is-poppins {
    font-family: 'Poppins', sans-serif;
}

.page-wrapper {
    width: 80rem;
    margin: 0 auto;
}

.navbar-wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.scrolling-wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
    overflow-x: auto;
    padding: .5rem .25rem 0 .25rem;
}

.project-navbar-wrapper {
    width: 90%;
    margin: 0 auto;
}

.sub-nav-button {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;}

.z-navbar-link {
    padding: 0;
}
.z-navbar-link:hover {
    opacity: .4;
}

.main-header-title {
    font-size: 3.25rem;
    font-weight: 700;
}

.main-header-subtext {
    margin-top: 1rem;
    font-size: 1.75rem;
    font-weight: 500;
}

.sticky-topbar {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 100;
    height: 6rem;
    width: 100%;
    background-color: white;
}

.home-left-area {
}

.home-left-section {
    position: -webkit-sticky ;
    position: sticky ;
    top: 6rem;
    z-index: 100;
}

.zemoji {
    -webkit-clip-path: circle();
            clip-path: circle();
}

.home-right-section-nav {
    position: -webkit-sticky ;
    position: sticky ;
    top: 6rem;
    z-index: 100;
}

.title-header {
    font-size: 2.5rem;
    font-weight: 800;
}

.header1 {
    font-size: 3.25rem;
    font-weight: 800;
}

.header2 {
    font-size: 3rem;
    font-weight: 800;
}

.header3 {
    font-size: 2.5rem;
    font-weight: 700;
}

.header4 {
    font-size: 1.5rem;
    font-weight: 700;
}

.header5 {
    font-size: 1.25rem;
    font-weight: 700;
}

.subtext1 {
    margin-top: 1rem;
    font-size: 1.25rem;
    font-weight: 400;
}

.subtext2 {
    margin-top: 1rem;
    font-size: 1rem;
    font-weight: normal;
}

.subtext3 {
    font-size: .75rem;
    font-weight: thin;
}

.home-project-image {
    border-radius: 1rem;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.home-project-title {
    font-size: 1.25rem;
    font-weight: 700;
}

.home-project-info {
    padding-top: 1rem;
}

/* Project Pages */
.project-page-wrapper {
    width: 76rem;
    padding: 1rem;
    margin: 0 auto;
}

.project-page-header-wrapper {
    width: 80rem;
    padding: none;
    margin: 0 auto;
}

.project-header-subtitle {
    margin-top: 3rem;
    font-size: 1.25rem;
    font-weight: 600;
}

.project-header-subtext {
    margin-top: 1rem;
    font-size: 1rem;
    font-weight: 400;
}

.project-page-title {
    font-size: 2rem;
    font-weight: 900;
    margin-top: 4rem;
}

.project-page-background {
    margin: 2rem 0;
}

.project-page-image {
    border-radius: .75rem;
}

.project-card-content {
    padding: 4rem;
}

/* IOS14conceptPage */
.ios14-section {
    width: 75%;
    margin: 4rem auto;
}

.ios14-title {
    font-family: 'SFPro';
    font-weight: 700;
    font-size: 3rem;
    color: rgb(44, 44, 46);
    margin: 1rem auto;
}

.ios14-subtext {
    font-family: 'SFPro';
    font-weight: 500;
    font-size: 1.5rem;
    color: rgb(99, 99, 102);
}

.ios14-footer-text {
    font-family: 'SFPro';
    font-weight: 400;
    font-size: 1rem;
    color: rgb(174, 174, 178);
}

/* Footer */
.footer {
    background-color: rgb(28,28,30);
}

/* 1440px SCREEN Breakpoint */
@media (max-width: 1400px) {
    .project-page-header-wrapper {
        width: 90%;
    }
    .project-page-wrapper {
        width: 80%;
    }

}

/* 1366px SCREEN Breakpoint */
@media (max-width: 1366px) {
    .page-wrapper {
        max-width: 70rem;
    }

}

/* 1200px SCREEN Breakpoint */
@media (max-width: 1200px) {
    .page-wrapper {
        max-width: 60rem;
    }
    .title-header {
        font-size: 2rem;
    }
    /* .project-page-wrapper {
        width: 54rem;
    } */


}

/* 1024px SCREEN Breakpoint */
@media (max-width: 1024px) {
    /* .project-page-header-wrapper {
        width: 90%;
    } */

}

/* LARGE SCREEN Breakpoint */
@media (max-width: 992px) {
    .page-wrapper {
        max-width: 50rem;
    }
    .title-header {
        font-size: 2.5rem;
    }
    .home-left-area {
        width: 66% !important;
        margin-bottom: 6rem;
    }
    .home-right-area {
        width: 100% !important;
    }
    .home-right-section-nav {
        top: 1rem;
    }
    .sticky-topbar {
        height: 4rem;
    }
    .project-page-wrapper {
        width: 90%;
    }

}

/* SMALL SCREEN Breakpoint */
@media (max-width: 834px) {
    .page-wrapper {
        max-width: 90%;
    }
    /* .project-page-wrapper {
        width: 45rem;
    } */

}

/* MEDIUM SCREEN Breakpoint */
@media (max-width: 768px) {
    .project-page-wrapper {
        width: 90%;
    }
    .home-left-area {
        width: 100% !important;
        margin-bottom: 6rem;
    }
    .home-project {
        width: 85%;
        margin: 0 auto;
    }
    .ios14-section {
        width: 90%;
        /* margin: 4rem auto; */
    }
    .ios14-title {
        font-size: 2rem;
    }
    .ios14-subtext {
        font-size: 1rem;
    }
}

/* 700px SCREEN Breakpoint */
@media (max-width: 700px) {
    .project-page-wrapper {
        width: 95%;
    }
    .project-page-header-wrapper {
        width: 95%;
    }
    .project-card-content {
        padding: 2rem;
    }
}

/* 500px SCREEN Breakpoint */
@media (max-width: 500px) {
    .ios14-title {
        font-size: 1.5rem;
    }
    .ios14-subtext {
        font-size: .75rem;
    }
}

/* MOBILE Breakpoint */
@media (max-width: 465px) {
    .title-header {
        font-size: 2rem;
    }
    .home-tab-text {
        font-size: .75rem;
    }

}

/* small MOBILE Breakpoint */
@media (max-width: 365px) {
    .title-header {
        font-size: 1.5rem;
    }
    .home-tab-text {
        font-size: .6rem;
    }

}






