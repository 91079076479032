.toggle {
    height: 30px;
    width: 60px;
    border-radius: 50px;
    margin: auto;
    background-image: linear-gradient(aqua, skyblue);
    position: relative;
    cursor: pointer;
}

.toggle.night {
    background-image: linear-gradient(midnightblue, rebeccapurple);
}

.notch {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: yellow;
    position: absolute;
    top: 5px;
    left: 5px;
    box-shadow: 0 0 5px yellow;
    z-index: 1;
    transition: all 0.3s ease;
}

.notch > .crater {
    background: burlywood;
    border-radius: 50%;
    position: absolute;
    opacity: 0;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.4) inset;
}
.night .crater {
    opacity: 0.4;
}

.crater:first-child {
    left: 2px;
    top: 3px;
    height: 3px;
    width: 8px;
    transform: rotate(-45deg);
}

.crater:nth-child(2) {
    left: 3px;
    bottom: 3px;
    height: 4px;
    width: 5px;
    transform: rotate(45deg);
}

.crater:last-child {
    right: 2px;
    top: 3px;
    height: 3px;
    width: 5px;
    transform: rotate(45deg);
}

.night > .notch {
    background: whitesmoke;
    box-shadow: 0 0 5px whitesmoke;
    transform: translate(30px, 0);
}

.shape {
    position: absolute;
    background: whitesmoke;
    border-radius: 50%;
    transition: all 0.3s ease;
}

.shape.sm {
    height: 2px;
    width: 10px;
    top: 40%;
    left: 70%;
}

.shape.md {
    height: 3px;
    width: 15px;
    top: 25%;
    left: 30%;
    z-index: 2;
}

.shape.lg {
    height: 3px;
    width: 20px;
    top: 60%;
    left: 40%;
}

.night .shape {
    background: lightgray;
    box-shadow: 0 0 10px 2px violet;
}

.night .shape.sm {
    height: 3px;
    width: 3px;
    transform: translate(-35px, -2px);
}

.night .shape.sm:first-of-type {
    height: 3px;
    width: 3px;
    transform: translate(-18px, 3px);
}

.night .shape.md {
    height: 5px;
    width: 5px;
    transform: translate(0, -3px);
}

.night .shape.lg {
    height: 6px;
    width: 6px;
    transform: translate(-15px, 0);
}